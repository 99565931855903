#admin-videos {
  padding: 25px 50px;
  min-width: 600px;

  .admin-table-row {
    grid-template-columns: 0.25fr 1fr 1fr 0.5fr 0.5fr 1fr;
  }

  .admin-table-column {
    padding: 5px;
    font-size: 1.4rem;
  }

  .page.upload-widget-container {
    text-align: center;
  }
}
