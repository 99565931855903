.auth {
  background-color: #181818;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 25px;
}

.auth-form-container {
  .auth-logo-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;

    img {
      width: 50%;
      max-width: 300px;
    }

    @include respond-below(sm) {
      img {
        width: 200px;
      }
    }
  }

  background-color: white;
  min-height: 300px;
  width: 400px;
  border-radius: 10px;
  padding: 50px 150px;
  // display: flex;
  // justify-content: center;
  // align-items: center;

  a {
    text-decoration: none;
    font-weight: bold;
    color: $gold;
  }

  @include respond-below(sm) {
    padding: 20px;
  }

  @include respond-below(xs) {
    width: 90%;
    margin: 0 auto;
  }
}

.auth-form {
  position: relative;
  width: 100%;

  h3 {
    text-align: center;
  }

  h2,
  h3 {
    margin-top: 0;
    margin-bottom: 50px;
  }

  .form-input-container {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 50px;
  }

  label {
    display: block;
    margin-bottom: 5px;
  }

  input {
    padding: 10px 5px;
    width: calc(100% - 10px);
    background-color: #f3f3f3;
    border: none;
  }

  .button-container {
    width: 80%;
    margin: 0 auto;
  }

  button {
    background-color: $gold;
    width: 100%;
    margin-top: 5px;
    padding: 10px 5px;
    border: none;
    border-radius: 2px;
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    color: white;
  }
}

.login-form-links {
  text-align: center;
  a {
    text-decoration: none;
    margin-top: 15px;
    color: $dark-gray;
    display: block;
  }
}

.form-error {
  position: absolute;
  top: 30px;
  width: 100%;
  color: $warning-color;
  text-align: center;
  font-size: 1.4rem;
}

#page-confirm-email {
  .auth-message {
    color: white;
    padding: 10px;
    max-width: 500px;
    margin: 0 auto;

    p {
      color: white;
    }
  }
}
