html {
  margin: 0;
  padding: 0;
  font-size: 62.5%;
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: $body-font !important;
  color: $primary-text-color;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.action-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;

  svg {
    margin-bottom: -2px;
    margin-right: 5px;
  }

  button {
    margin: 10px;
  }
}

button.edit {
  background-color: $gray;
  color: white;

  // &:hover {
  //     background-color: darken($gray, 15%);
  // }
}

button.delete {
  background-color: $warning-color;
  color: white;

  // &:hover {
  //     background-color: darken($warning-color, 15%);
  // }
}

button,
.button {
  cursor: pointer;
  box-shadow: $small-shadow;
  border: none;
  border-radius: 2px;
  font-size: 1.6rem;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
}

.view-container {
  height: 100vh;
  margin-left: 200px;
  background-color: #ededed;
  position: relative;
  overflow: scroll;

  @include respond-below(sm) {
    margin-left: 45px;
  }
}

.view-container.no-user {
  margin-left: 0;
}

.search-form {
  padding-bottom: 25px;

  input {
    width: 200px;
    border: 0;
    padding: 5px 10px;
    font-size: 1.8rem;
    border-radius: 3px;
  }
}

.page-heading {
  font-size: 2rem;
  text-align: center;
  // border-bottom: 1px solid $dark-gray;
  color: $dark-gray;
  font-weight: bold;
}

.page {
  padding: 25px 100px;
  background-color: #ededed;

  @include respond-below(md) {
    padding: 15px 50px;
  }

  @include respond-below(xs) {
    padding: 15px;
  }
}

.align-center {
  text-align: center;
}

.app-container.loading {
  opacity: 0;
}

.view.loading {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.view {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.app-container {
  opacity: 1;
  transition: all 0.5s ease;
}

.link-container {
  width: 100%;
  text-align: center;
  margin-bottom: 15px;

  div,
  a {
    cursor: pointer;
  }

  .delete-link {
    font-weight: bold;
  }
}

.container-fixed {
  width: 100%;
  position: fixed;
}

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  opacity: 1;
  transition: 1s opacity;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.half {
  width: 50%;

  @include respond-below(sm) {
    width: 100%;
  }
}

.half .flex-container {
  @include respond-below(md) {
    .half {
      width: 100%;
    }
  }
}

.sort-container {
  width: 100%;
  padding: 10px;
  position: relative;

  .link {
    margin-bottom: 10px;
  }

  .link,
  .menu {
    cursor: pointer;
  }

  svg {
    margin-bottom: -2px;
    margin-left: 5px;
  }

  .menu {
    position: absolute;
    z-index: 100;
    box-shadow: $small-shadow;
    background-color: white;
    border-radius: 2px;
    color: black;
  }

  .menu-item {
    min-width: 150px;
    padding: 10px 20px;
    text-align: left;
  }

  .menu-item:hover {
    background-color: $light-gray;
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .modal-header {
    display: flex;
    width: 100%;
    max-width: 650px;
    margin-bottom: 10px;
    background-color: $white;
    justify-content: space-between;
    align-items: center;
    // border-bottom: 1px solid $gold;
    border-radius: 5px;
    justify-content: center;
    .close {
      cursor: pointer;
    }
    h2 {
      color: $dark-gray;
      display: flex;
    }
  }
  .modal-content {
    width: 40%;
    max-width: 650px;
    height: 500px;

    background-color: $white;
    border-radius: 5px;
    box-shadow: $medium-shadow;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
