#sidebar {
  width: 160px;

  @include respond-below(xs) {
    width: 45px;
  }
}
.sidebar {
  position: fixed;
  background-color: $dark-gray;
  color: white;
  padding: 20px;
  height: 100vh;
  h4 {
    color: $gold;
  }

  .menu-icon {
    cursor: pointer;
    position: absolute;
    text-align: center;

    svg {
      margin: 0 auto;
      fill: white;
      font-size: 2.5rem;
    }
  }

  a {
    text-decoration: none;
    color: white;
    margin-bottom: 25px;
    display: block;
    cursor: pointer;
    font-weight: bold;
  }

  .menu-icon {
    display: none;
  }

  .logo-container {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;

    img {
      max-width: 300px;
      width: 100%;
    }

    .sidebar-menu {
      margin-top: 100px;
      text-align: center;
    }

    @include respond-below(sm) {
      padding: 0;

      img {
        width: 40px;
      }
    }
  }

  @include respond-below(sm) {
    width: 45px;
    padding: 0;

    .sidebar-menu {
      display: none;
    }

    .menu-icon {
      display: block;
      top: 10px;
      left: 10px;
      z-index: 100;
    }
  }
  .series-list-container {
    padding: 10px;
    margin: 0 0 15px 0;
    background-color: $light-gray;
    border: 1px solid $gold;
    border-radius: 5px;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.3);

    a {
      color: $dark-gray;
      text-decoration: none;
      font-weight: bold;
    }
  }
}

.sidebar-active {
  @include respond-below(sm) {
    width: 100%;
    z-index: 100;
    padding: 20px;

    .sidebar-menu {
      display: block;
    }

    .menu-icon {
      top: 10px;
      left: 10px;
      z-index: 100;
    }

    svg {
      font-size: 3rem;
    }
  }
}
