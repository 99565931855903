.admin-table {
  margin-top: 50px;
  width: 90%;
  margin-left: 5%;
  min-width: 500px;
  a {
    text-decoration: none;
    color: $gold;
    font-weight: bold;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  li:nth-of-type(even) {
    background-color: #f3f3f3;
  }

  input[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin-top: 0;
  }

  li.show-more-row {
    display: flex;
    justify-content: center;
    grid-template-columns: 1fr;

    button {
      box-shadow: none;
      cursor: pointer;
      background-color: $dark-gray;
      color: white;
      font-size: 1.4rem;
      margin: 10px 5px;
    }
  }

  .search-form {
    input {
      font-size: 1.6rem;
    }

    button {
      cursor: pointer;
      background-color: $gold;
      color: white;
      box-shadow: none;
      border-radius: 2px;
      font-size: 1.4rem;
      padding: 5px;
      width: 30px;
      margin-left: 5px;
    }
  }
}

.admin-table-row {
  background-color: white;
  width: 100%;
  display: grid;
  grid-template-columns: 0.25fr 1fr 1fr 1fr 1fr;

  div {
    padding: 5px 0px 5px 20px;
  }

  svg {
    margin-bottom: -2px;
    margin-left: 5px;
    cursor: pointer;
  }
}

.admin-table-header {
  font-weight: bold;
  border-bottom: 1px solid $dark-gray;
  background-color: $dark-gray;
  color: white;
  border-radius: 5px 5px 0 0;
}

// .admin-table-column {
//     display: flex;
//     align-items: center;

//     p {
//         margin: 0;
//         font-size: inherit;
//     }

//     p:first-of-type {
//       text-decoration: underline; cursor: pointer; font-size: 1.4rem;
//     }

//     a {
//         color: black;
//         text-decoration: underline;
//         cursor: pointer;
//     }

//     svg {
//         margin-bottom: -2px !important;
//         margin-left: 5px;
//         height: 100%;
//         cursor: pointer;
//     }
// }

// .admin-list-column {
//     display: block;
// }

// .admin-table-column {
//     background-color: white !important;
// }

// .admin-table-header .admin-table-column {
//     background-color: $green !important;
// }
.admin-table-toggles {
  display: flex;
  flex-direction: row;
}

.delete-container {
  button {
    background-color: $dark-gray;
    color: white;
  }
}
.toggle-container {
  margin-left: 2px;
  button {
    background-color: $dark-green;
    margin: 0 2px 0 2px;
    color: white;
  }
}
