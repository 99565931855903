.upload-widget-container {
  //   width: calc(100% - 50px);
  padding: 25px;
}

.upload-items-container {
  display: block;
}

.upload-item {
  max-width: 800px;
  margin: 15px auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .thumbnail {
    background-size: cover;
    background-position: center;
    height: 250px;
    width: 400px;

    @include respond-below(sm) {
      width: 300px;
      height: 200px;
    }

    @include respond-below(xs) {
      width: 80%;
      height: 250px;
      margin: 0 auto;
    }
  }

  .upload-remove-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .upload-form-container {
    width: 300px;
    padding: 25px;
    display: flex;
    flex-direction: column;

    .input-error {
      margin-bottom: 10px;
    }

    input {
      height: 30px;
      width: 100%;
      margin-bottom: 15px;
      border: none;
      border-radius: 5px;
      font-size: 1.6rem;
    }
    textarea {
      min-height: 100px;
      height: auto;
      width: 100%;
      margin-bottom: 15px;
      border: none;
      border-radius: 5px;
      font-size: 1.6rem;
      resize: none;
      font-family: inherit;
    }

    button {
      display: block;
      background-color: $warning-color;
      color: $white;
      font-size: 1.4rem;
      max-width: 100px;
    }
  }
}

#upload_widget {
  background-color: $dark-gray;
  margin: 0 auto;
}

#upload-form {
  .button-container {
    display: flex;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;

    button {
      background-color: $accent-color;
      color: $white;
    }
  }
}
