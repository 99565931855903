#admin-tags {
  padding: 25px 50px;
  min-width: 600px;

  .admin-table-row {
    grid-template-columns: 0.25fr 1fr 1fr;
  }

  .admin-table-column {
    padding: 5px;
    font-size: 1.4rem;
  }

  .page.upload-widget-container {
    text-align: center;
  }

  .add-tag-form {
    width: 100%;

    flex-direction: row;
    input {
      font-size: 1.6rem;
      width: 200px;
      border: 0;
      padding: 5px 10px;
      border-radius: 5px;
    }

    button {
      cursor: pointer;
      background-color: $gold;
      color: white;
      margin-top: 12px;
      margin-bottom: 12px;
      // box-shadow: none;
      // border-radius: 2px;
      // font-size: 1.4rem;
      // padding: 5px;

      // margin-left: 5px;
    }
  }
}
