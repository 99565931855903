#video {
  h1,
  h2,
  h3 {
    text-align: center;
    font-weight: bold;
  }
}

.video-preview {
  width: 50%;
  margin: 25px auto;
  min-width: 200px;
  max-width: 800px;
}

.video-details {
  width: 50%;
  margin: 25px auto;
  min-width: 200px;
  max-width: 800px;
}

.video-details-item {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 150px 400px;
  margin-top: 10px;
  margin-bottom: 10px;

  p {
    margin: 0;
  }

  .name {
    font-weight: bold;
  }
}

.save {
  background-color: $dark-green;
  color: $white;
}

#edit-form {
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: 25px;

  .edit-form-field {
    padding: 10px 25px;
    display: block;
  }

  label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }

  input {
    padding: 5px;
    width: calc(100% - 10px);
    height: 30px;
    border: none;
    font-size: 1.6rem;
    border-radius: 5px;
  }

  .button-container {
    display: flex;
    justify-content: center;

    button {
      background-color: $dark-gray;
      color: white;
    }
  }
}

#delete-form {
  max-width: 500px;
  margin: 0 auto;

  p {
    font-size: 1.6rem;
  }

  .button-container {
    display: flex;
    justify-content: center;

    button {
      background-color: $warning-color;
      color: white;
      margin: 5px;
    }

    button.cancel {
      background-color: gray;
    }
  }
}
