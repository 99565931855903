#library {
  .search-form {
    padding: 0 25px;
  }

  input {
    border: 1px solid $dark-gray;
    box-shadow: inset 0px 0px 3px rgba(34, 34, 34, 0.3);
  }
  textarea {
    border: 1px solid $dark-gray;
    box-shadow: inset 0px 0px 3px rgba(34, 34, 34, 0.3);
  }

  .no-results {
    padding: 10px;
  }

  .search-results {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 15px;

    @include respond-below(xs) {
      justify-content: center;
    }
  }
  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .button {
    text-decoration: none;
    background-color: $dark-gray;
    line-height: 28px;
    color: $white;
    font-family: "Lato", sans-serif;
  }
}

.library-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 15px;
  border: 1px solid $gold;
  border-radius: 5px;
  box-shadow: $small-shadow;
  @include respond-below(xs) {
    justify-content: center;
  }
}

.library-item {
  position: relative;
  margin: 10px;
  width: 180px;
  border: 1px solid $gold;
  background-color: $dark-gray;
  color: white;
  font-size: 1.4rem;
  border-radius: 5px;
  padding: 10px;
  box-shadow: $medium-shadow-gold;

  .thumbnail {
    border: 1px solid $gold;
    border-radius: 5px;
    width: calc(100% - 22px);
    height: 125px;
    background-size: cover;
    background-position: center;
    padding: 10px;
    font-size: 1.8rem;
    font-weight: bold;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    border: 1px solid $gold;
    padding: 5px 10px 5px 10px;
    margin-top: 10px;
    border-radius: 5px;
    background-color: $gray;
    box-shadow: $small-shadow-gold;
    .more {
      cursor: pointer;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .library-item-tags-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    flex-wrap: wrap;
    padding: 5px 10px 5px 10px;
    margin-top: 10px;
    border: 1px solid $gold;
    border-radius: 5px;
    // background-color: $gray;
    box-shadow: $small-shadow-gold;
    min-height: 30px;
    .tag {
      margin: 2px;
      padding: 4px;
      border: 1px solid $gold;
      border-radius: 5px;
      background-color: $gray;
      box-shadow: $small-shadow-gold;
      min-width: unset;
    }
  }

  .menu {
    background-color: $white;
    color: black;
    box-shadow: $small-shadow;
    position: absolute;
    z-index: 10;
    width: 200px;
    right: -25px;
    bottom: -50px;

    a,
    .menu-item {
      text-decoration: none !important;
      color: inherit !important;
      width: calc(100% - 10px);
      display: block;
      padding: 5px;
      cursor: pointer;
    }

    a:hover,
    .menu-item:hover {
      background-color: #ededed;
    }
  }
}

.selected-video {
  border: 3px solid $blue !important;
  box-shadow: 0px 0px 5px $blue;
}

#create-series-button {
  background-color: $gold !important;
  color: $black !important;
  margin-right: 26px;
}
