.information {
  padding: 25px;
  max-width: 800px;
  margin: 0 auto;

  .logo-container {
    width: 100px;
    img {
      width: 100%;
    }
  }

  a {
    color: $gold;
    font-weight: bold;
  }

  h2 {
    font-size: 3rem;
    border-bottom: 1px solid gray;
    padding-bottom: 8px;
  }

  h3 {
    font-size: 2.4rem;
  }

  h5 {
    font-size: 2rem;
  }

  ul.no-style {
    list-style: none;
  }

  li {
    margin-bottom: 15px;

    span {
      font-weight: bold;
      margin-right: 5px;
    }
  }
}
