#admin-series {
  padding: 25px 50px;
  min-width: 600px;

  .admin-table-row {
    grid-template-columns: 0.25fr 1.25fr 0.35fr 0.35fr 0.5fr 1fr 0.5fr;
  }

  .admin-table-column {
    padding: 5px;
    font-size: 1.4rem;
  }

  .action-button-container {
    button {
      background-color: $dark-gray;
      color: white;
    }
  }

  .delete-container {
    button {
      margin-right: 15px;
    }

    button.featured {
      background-color: $dark-green;
    }

    button.premium {
      background-color: $gold;
    }
  }
}
