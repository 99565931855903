@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,700;1,300;1,700&display=swap");

$body-font: "Lato", sans-serif;
$header-font: "Lato", sans-serif;
$accent-font: "Lato", sans-serif;

$header-weight: 700;
$body-weight: 400;

$black: #141518;
$blue: #008bff;
$white: #fff;
$gold: #d5a742;
$red: #de1a1a;
$green: #55ff33;
$dark-green: #149b18;
$dark-gray: #222222;
$gray: #7e7e82;
$light-gray: #fafafa;

$primary-text-color: $black;
$second-text-color: $dark-gray;
$links-color: $dark-green;
$accent-color: $gold;
$warning-color: $red;

$small-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
$small-shadow-gold: 0 1px 3px rgba(213, 167, 66, 0.12),
  0 1px 2px rgba(213, 167, 66, 0.24);

$medium-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
$medium-shadow-gold: 0 3px 6px rgba(213, 167, 66, 0.16),
  0 3px 6px rgba(213, 167, 66, 0.23);
