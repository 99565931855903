.user-details {
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
  background-color: white;
  padding: 50px;
  overflow-x: scroll;

  @include respond-below(xs) {
    padding: 10px;
  }

  .user-details-item {
    display: grid;
    grid-gap: 25px;
    grid-template-columns: 60px 1fr;
    margin-bottom: 30px;
  }

  .name {
    font-weight: bold;
    text-transform: uppercase;
    text-align: right;
  }

  .details {
    text-overflow: ellipsis;
  }
}

#delete-form {
  margin-bottom: 25px;
}

.user-title {
  text-align: center;
}

#edit-form {
  textarea {
    min-height: 100px;
    padding: 5px;
    width: calc(100% - 10px);
    height: 30px;
    border: none;
    border-radius: 5px;
    font-size: 1.6rem;
    resize: none;
    font-family: inherit;
  }
}
