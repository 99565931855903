.tag {
  min-width: 100px;
  text-align: center;
  font-size: 1.2rem;
}

.form-input-container.tags {
  width: 100%;
}

#tags-form-container {
  width: 100%;
}

#tags-form-container {
  border-radius: 2px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background-color: white;
  padding: 5px;
  width: calc(100% - 10px);

  input {
    border: 1px solid black !important;
  }

  .tag {
    background-color: $dark-gray;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 1.4rem;
    color: white;
    padding: 5px 10px;
    text-align: center;
    font-size: 1.4rem;
    border-radius: 1000px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      height: 20px;
      width: 20px;
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: -2px;
      cursor: pointer;
    }
  }

  .tags {
    margin-top: 0;
    margin-bottom: 0;
  }

  input {
    background: transparent;
    outline: none;
    border: none;
    width: 100%;
  }

  .create-new-tag-container {
    text-align: center;
  }

  .suggestions-container {
    z-index: 500;
    position: absolute;
    background-color: white;
    right: 0;
    top: 45px;
    width: 250px;
    box-shadow: $small-shadow;

    ul {
      list-style: none;
      padding-left: 0;
      margin: 0;
    }

    li {
      cursor: pointer;
      padding: 5px;
    }

    li:hover {
      background-color: $light-gray;
    }

    li.add-new-tag-container:hover {
      background-color: white;
    }

    .create-new-tag {
      background-color: $gold;
      border: 0;
      font-size: 1.4rem;
    }
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;

  .tag {
    background-color: $gold;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 5px 15px 5px 15px;
    border-radius: 1000px;
    color: black;
    font-weight: bold;
    cursor: pointer;
  }
}
