#series {
  .action-link {
    font-size: 1.4rem;
    cursor: pointer;
    color: $gold;
    margin-left: 15px;
  }

  .tags {
    display: flex;
    justify-content: center;

    div {
      background-color: $dark-gray;
      color: white;
      padding: 5px 15px;
      border-radius: 1000px;
      margin: 2px;
      min-width: 50px;
      text-align: center;
      font-size: 1.4rem;
    }
  }
  .complete {
    background-color: $dark-green;
    color: $white;
  }

  .series-container {
    margin-top: 15px;
  }

  #reorder {
    background-color: $gray;
    color: white;
  }

  .description {
    margin: 0 auto;
    text-align: center;

    font-size: 1.8rem;
  }
  .search-form {
    button {
      margin-left: 10px;
      background-color: $dark-green;
      color: white;
    }
  }

  .search-results {
    width: 80%;
    max-height: 510px;
    overflow-y: auto;
    margin-top: 10px;
    margin-bottom: 25px;
    background-color: $white;
    border-radius: 5px;
    border: 1px solid $gold;
    padding: 2px 4px 2px 4px;
    .item {
      display: flex;
      border-radius: 5px;
      margin-top: 4px;
      margin-bottom: 4px;
      background-color: $dark-gray;
      // width: calc(100% - 25px);
      justify-content: flex-start;
      padding: 10px;
      @include respond-below(xs) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      a {
        text-decoration: none;
        cursor: pointer;
        font-weight: bold;
      }

      svg {
        margin-left: 5px;
        margin-bottom: -2px;
      }
    }

    .item:nth-child(even) {
      background-color: $gray;
    }
    .search-results-info {
      flex: 1;
      border-radius: 5px;
      background-color: $gray;
      width: 50%;
      padding: 10px;
      border: 1px solid $gold;
      margin-left: 10px;
      // box-shadow: $small-shadow-gold;
      box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.3);
      @include respond-below(xs) {
        width: 90%;
        margin: 0 20px;
      }
    }

    .title {
      display: flex;
      flex: 1;
      font-weight: bold;
      justify-content: flex-start;
      color: $white;
      border: 1px solid $gold;
      padding: 5px 10px 5px 10px;
      margin-bottom: 10px;
      border-radius: 5px;
      background-color: $dark-gray;
      box-shadow: $small-shadow-gold;
    }
    .description {
      display: flex;
      flex: 1;
      justify-content: flex-start;
      font-weight: bold;
      color: $white;
      border: 1px solid $gold;
      padding: 5px 10px 5px 10px;
      border-radius: 5px;
      background-color: $dark-gray;
      box-shadow: $small-shadow-gold;
      max-height: 100px;
      overflow: scroll;
    }

    .thumbnail {
      height: 100px;
      width: 150px;
      background-size: cover;
      background-position: center;
      align-self: center;
      border-radius: 5px;
      border: 0.5px solid $light-gray;
      @include respond-below(xs) {
        width: 100%;
        height: 200px;
        margin-bottom: 4px;
      }
    }

    .action {
      display: flex;
      align-self: center;
      padding: 15px;
      margin: 10px 0px 10px 10px;
      border: 1px solid $gold;
      border-radius: 5px;
      max-height: 45px;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      color: $black;
      background-color: $white;
      box-shadow: $medium-shadow;
      cursor: pointer;
      @include respond-below(xs) {
        padding: 15px 30px;
      }
    }
    .action:hover {
      background-color: $gold;
      color: $white;
    }
  }
}

.series-item {
  background-color: $dark-gray;
  width: 100%;
  margin-top: 20px;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid $gold;
  box-shadow: $medium-shadow-gold;
  position: relative;
  display: flex;

  .thumbnail {
    height: auto;
    width: 640px;
    background-color: black;
    background-size: cover;
    background-position: center;
  }

  @include respond-below(xs) {
    width: 80%;
    margin-left: 10%;
    flex-direction: column;
  }
}

video {
  border-radius: 3px 0px 0px 3px;
}

.series-item-info {
  border-radius: 5px;
  background-color: $gray;
  color: white;
  width: 50%;
  padding: 10px;
  border: 1px solid $gold;
  margin-left: 10px;
  // box-shadow: $small-shadow-gold;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.3);
  @include respond-below(xs) {
    width: 80%;
  }

  a {
    margin-left: 10px;
    font-size: 1.4rem;
    color: $gold;
    text-decoration: none;
    cursor: pointer;
  }

  h4 {
    margin: 0;
  }

  h4,
  p {
    color: white;

    @include respond-below(xs) {
      font-size: 1.4rem;
    }
  }

  svg {
    margin-left: 10px;
    margin-bottom: -2px;
  }

  svg:hover {
    fill: $gold;
  }
  .title {
    border: 1px solid $gold;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    background-color: $dark-gray;
    box-shadow: $small-shadow-gold;
  }

  .desc {
    text-align: left;
    padding-top: 10px;
    padding-bottom: 20px;
    min-height: 40%;
    height: auto;
    max-height: 40%;
    font-size: 1.4rem;
    overflow-y: scroll;
    border: 1px solid $gold;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    background-color: $dark-gray;
    box-shadow: $small-shadow-gold;
  }
}

.series-item-settings {
  background-color: $dark-gray;
  color: white;
  // border-left: 1px solid black;

  span {
    margin-bottom: 10px;
  }

  @include respond-below(xs) {
    //  border-left: 0;
    width: calc(100% - 30px);
  }
}

.series-item-action {
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 100;
  margin: 10px;

  .remove {
    text-decoration: none;
    color: white;
    margin-top: 25px;
    cursor: pointer;
    font-weight: bold;

    svg {
      margin-bottom: -2px;
      margin-left: 5px;
    }
  }
}

.series-item-number {
  position: absolute;
  bottom: 45%;
  left: -30px;
  font-size: 3rem;
  font-weight: bold;
  background-color: $accent-color;
  width: 40px;
  color: white;
  text-align: center;
  border-radius: 5px;
  box-shadow: $small-shadow;
  z-index: 100;

  @include respond-below(xs) {
    bottom: 80%;
  }
}

.draggable {
  cursor: pointer;
}

.timer {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
  border: 1px solid $gold;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  background-color: $dark-gray;
  box-shadow: $small-shadow-gold;
  .css-319lph-ValueContainer {
    padding: 2px;
  }

  .timer-item {
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
  }

  label {
    margin-left: 5px;
    line-height: 2;
  }

  .action-link {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      // padding: 0;
      background-color: $gold;

      padding: 4px 8px;
      box-shadow: $small-shadow;
      border: none;
      font-size: 1.6rem;
      font-weight: bold;
      border-radius: 5px;
    }
  }

  .css-b62m3t-container {
    padding: 0;
    width: 45px;

    text-align: center;
    margin-right: 10px;
    color: black;
    font-size: 1.4rem;
  }

  svg {
    margin-left: 0;
  }
}

.player {
  border: 0.5px solid $gray;

  border-radius: 5px;
  max-height: 250px !important;
  @include respond-below(xs) {
    width: 100% !important;
  }

  video {
    max-height: 250px;
  }
}

#series-form {
  max-width: 650px;
  width: calc(100% - 50px);
  margin: 0 auto;
  padding-bottom: 25px;

  .edit-form-field {
    width: 70%;
    padding: 10px 25px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    border-radius: 5px;
  }

  label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }

  input {
    padding: 5px;
    width: calc(100% - 10px);
    height: 30px;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 1.6rem;
  }

  .button-container {
    display: flex;
    justify-content: center;

    button {
      background-color: $gray;
      color: white;
      margin: 10px;
    }

    button[type="submit"] {
      background-color: $dark-gray;
    }
  }
}
