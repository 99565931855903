@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

body {
    font-family: $body-font;
    color: $dark-gray;
    font-size: 1.6rem;
    font-weight: $body-weight;
} 

label {
    font-weight: 500;
}

.delete-link {
    margin-top: 15px;
    font-weight: bold;
    cursor: pointer;
}

.error-message {
    text-align: center;

    .message {
        margin-top: 100px;
    }
}

h1,h2,h3,h4,h5,h6 {
    font-weight: $header-weight;
    font-family: $header-font;
    color: $primary-text-color;
}

.normal-weight {
    font-weight: 400;
}

p {
    font-family: $body-font;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.5;
    color: $dark-gray;
}

h1 { 
    font-size: 4rem; 

    @include respond-below(sm) {
        font-size: 3rem;
    }
}
h2 { font-size: 3.6rem; }
h3 { font-size: 2.4rem; }
h4 { font-size: 2rem; }


.white-text {
    color: white;
}

.red-text {
    color: $warning-color;
}


span.optional {
    font-size: 1.2rem;
    font-style: italic;
    font-weight: normal;
}

@font-face {
    font-family: "FontAwesome";
    font-weight: normal;
    font-style : normal;
           src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?v=4.3.0");
           src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?#iefix&v=4.3.0") format("embedded-opentype"),
                 url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff2?v=4.3.0") format("woff2"),
                 url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff?v=4.3.0") format("woff"),
                 url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.ttf?v=4.3.0") format("truetype"),
                 url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular") format("svg");
  }

 