textarea:focus,
input:focus {
  outline: none;
}

.input-error {
  margin-top: 10px;
  color: $warning-color;
}

.form-error-inline {
  text-align: center;
  color: $warning-color;
}
.description-field {
  min-height: 100px;
  padding: 5px;
  width: calc(100% - 10px);
  height: 30px;
  border: 1px solid black;
  border-radius: 5px;
  font-size: 1.6rem;
  resize: none;
  font-family: inherit;
}
