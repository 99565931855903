#admin-users {
    min-width: 1000px;
    padding: 25px 50px;

    .admin-table-row {
        grid-template-columns: 0.25fr 1fr 1.5fr 1fr 1fr 1fr 1fr;
    }

    .admin-table-column {
        padding: 5px;
        font-size: 1.4rem;
    }

    .action-button-container {
        button {
            background-color: $dark-gray;
            color: white;
        }
    }
}
